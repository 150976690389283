import React, {useState} from 'react';
import useConfig from "../qnect-ui/qnect-config";
import '../../assets/styles/auto-renewal.css';
import '../../assets/styles/common-styles.css';
import CollapseArrow from '../../assets/Arrow.svg';
import {
    _getLang,
} from "../../store/system/service";
import {connect} from "react-redux";

const TOSSectionToggle = (props) => {
    const cfg = useConfig(props.lang);

    const [toggleArrow, setToggleArrow] = useState(Array(1).fill().map(() => Array(1).fill(false)));
    const toggledContent = useState(Array(1).fill().map(() => Array(1).fill(0)));

    const showToggledContent = (contentRow) => {
        toggleArrow[0][contentRow - 1] = !toggleArrow[0][contentRow - 1];
        setToggleArrow(state => [...state, toggleArrow])
        toggledContent[0][contentRow - 1] = (toggleArrow[0][contentRow - 1]) ? 1 : 0;
    }

    const toggleArrowAction = (rowNum, colNum) => {
        return (
            <div className="Toggler" onClick={() => showToggledContent(colNum + 1)}>
                <div>{ cfg('labels.termsAndConditions') }</div>
                <div>
                    <img src={CollapseArrow}
                         className={toggleArrow[rowNum][colNum] && toggledContent[rowNum][colNum] === 1 ? 'FlipArrow' : ''}
                         alt=""/>
                </div>
            </div>);
    }

    return (
        <div className="TOS-list">
            { toggleArrowAction(0, 0) }
            {(toggleArrow[0][0] && toggledContent[0][0]) &&
                <div>
                    <h3>{cfg('labels.MotorInsuranceRenewalCampaign.TC1_Title')}</h3>
                    <div dangerouslySetInnerHTML={{__html: cfg("labels.MotorInsuranceRenewalCampaign.TC1_Details")}}></div>
                    <h3>{cfg('labels.MotorInsuranceRenewalCampaign.TC2_Title')}</h3>
                    <div dangerouslySetInnerHTML={{__html: cfg("labels.MotorInsuranceRenewalCampaign.TC2_Details")}}></div>
                    <h3>{cfg('labels.MotorInsuranceRenewalCampaign.TC3_Title')}</h3>
                    <table className={"toggleTable"}>
                        <thead>
                            <tr>
                                <td>{cfg('labels.MotorInsuranceRenewalCampaign.TC3_Table_Details.reward_header')}</td>
                                <td>{cfg('labels.MotorInsuranceRenewalCampaign.TC3_Table_Details.dpar_header')}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="number-col">
                                <td>{cfg('labels.MotorInsuranceRenewalCampaign.TC3_Table_Details.reward_item1')}</td>
                                <td>{cfg('labels.MotorInsuranceRenewalCampaign.TC3_Table_Details.dpar_item1')}</td>
                            </tr>
                            <tr class="number-col">
                                <td>{cfg('labels.MotorInsuranceRenewalCampaign.TC3_Table_Details.reward_item2')}</td>
                                <td>{cfg('labels.MotorInsuranceRenewalCampaign.TC3_Table_Details.dpar_item2')}</td>
                            </tr>
                            <tr class="number-col">
                                <td>{cfg('labels.MotorInsuranceRenewalCampaign.TC3_Table_Details.reward_item3')}</td>
                                <td>{cfg('labels.MotorInsuranceRenewalCampaign.TC3_Table_Details.dpar_item3')}</td>
                            </tr>
                            <tr class="number-col">
                                <td>{cfg('labels.MotorInsuranceRenewalCampaign.TC3_Table_Details.reward_item4')}</td>
                                <td>{cfg('labels.MotorInsuranceRenewalCampaign.TC3_Table_Details.dpar_item4')}</td>
                            </tr>
                            <tr class="number-col">
                                <td>{cfg('labels.MotorInsuranceRenewalCampaign.TC3_Table_Details.reward_item5')}</td>
                                <td>{cfg('labels.MotorInsuranceRenewalCampaign.TC3_Table_Details.dpar_item5')}</td>
                            </tr>
                            <tr class="number-col">
                                <td>{cfg('labels.MotorInsuranceRenewalCampaign.TC3_Table_Details.reward_item6')}</td>
                                <td>{cfg('labels.MotorInsuranceRenewalCampaign.TC3_Table_Details.dpar_item6')}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h3>{cfg('labels.MotorInsuranceRenewalCampaign.TC4_Title')}</h3>
                    <div
                        dangerouslySetInnerHTML={{__html: cfg("labels.MotorInsuranceRenewalCampaign.TC4_Details")}}></div>
                    <h3>{cfg('labels.MotorInsuranceRenewalCampaign.TC5_Title')}</h3>
                    <div dangerouslySetInnerHTML={{__html: cfg("labels.MotorInsuranceRenewalCampaign.TC5_Details")}}></div>
                    <h3>{cfg('labels.MotorInsuranceRenewalCampaign.TC6_Title')}</h3>
                    <div dangerouslySetInnerHTML={{__html: cfg("labels.MotorInsuranceRenewalCampaign.TC6_Details")}}></div>
                    <h3>{cfg('labels.MotorInsuranceRenewalCampaign.TC7_Title')}</h3>
                    <div dangerouslySetInnerHTML={{__html: cfg("labels.MotorInsuranceRenewalCampaign.TC7_Details")}}></div>
                </div>
            }

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state)
    }
};

export default connect(mapStateToProps, null)(TOSSectionToggle);
