import * as type from "./types";
import { updateObject } from "../../utils/utility";

const INITIAL_STATE = {
    policyNumber: '',
    productType: '',
    catalogCode: '',
    product: '',
    country: '',
    language: '',
    effectiveStart: '',
    effectiveEnd: '',
    agentNumber: '',
    agentEmail: '',
    account: '',
    email: '',
    intermediaryId: '',
    lifeCycleId: '',
    amount: 0,
    cardHolder: '',
    cardNumber: '',
    expiry: '',
    cvv: '',
    paymentStatus: '',
    complete: false,
    errorMessage: '',
    agentName: '',
    agentEmailAddress: '',
    agentMobileNumber: '',
    payment: {},
    applicant: {},
    accountNumber: '',

};

const storePolicyPayment = (state, action) => {
    let newPolicy = action.data;

    if (!action.data) {
        newPolicy = INITIAL_STATE;
    }

    return updateObject(
        state,
        newPolicy
    )
}


export const policyPaymentReducer = (state = INITIAL_STATE, action) => {
    switch ( action.type ) {
        case type.POLICY_PAYMENT_STORE:
            return storePolicyPayment(state, action);
        case type.POLICY_PAYMENT_FETCH_FAILED:
            return updateObject(
                state,
                { error: true }
            );
        default:
            return state;
    }
}
